type GetInfraMigrationStatusResponse = {
  is_migrated_to_sc_infra: boolean;
};

export interface GetInfraMigrationStatusError extends Error {
  message: string;
  code?: number;
}

export const getInfraMigrationStatus = async (): Promise<GetInfraMigrationStatusResponse> => {
  const uri = '/api/v3/training/v1/Training:GetInfraMigrationStatus';

  const response: GetInfraMigrationStatusResponse = await fetch(uri, { method: 'GET' }).then(
    res => {
      if (!res.ok) {
        const err: GetInfraMigrationStatusError = new Error(
          'Failed to get tokens with status code ' + res.status
        );
        err.code = res.status;
        throw err;
      }
      return res.json();
    }
  );

  return response;
};
