import { ErrorLogger } from '@edapp/monitoring';
import type { RequestTypes } from '@edapp/request';
import {
  PLATFORM_QUERY_PARAM,
  PLATFORM_LOCAL_STORAGE,
  validatePlatformQueryParam
} from '@edapp/sc-web-ui';
import type { Platform } from '@edapp/sc-web-ui';
import { queryParamAccessor } from '@edapp/utils';
import { HostedWebviewUtils } from '@edapp/uxp-webview';
import type { History } from 'history';
import qs from 'qs';
import { once } from 'lodash-es';

const doLogout = (
  history: History,
  evt: RequestTypes.UnauthorizedEventType | null,
  from: 'api-unauthorized' | 'get-infra-migration-status'
) => {
  const { pathname, search, hash } = window.location;
  const platform = queryParamAccessor<Platform>(
    PLATFORM_QUERY_PARAM,
    PLATFORM_LOCAL_STORAGE,
    validatePlatformQueryParam
  );
  if (platform === 'ios' || platform === 'android') {
    // To not trigger this for mobile iOS/Android
    return;
  }

  ErrorLogger.captureEvent('Unauthorized', 'info', { evt, from });
  HostedWebviewUtils.triggerEvent('unauthorized');

  // Used for v2 of SC Training integration
  HostedWebviewUtils.triggerBootstrapError('unauthorized');

  let logoutPath = '/logout';
  if ((!!pathname && pathname !== '/') || !!search || !!hash) {
    const path = qs.stringify({ redirectRoute: `${pathname}${search}${hash}` });
    logoutPath += `?${path}`;
  }
  history.push(logoutPath);
};

/**
 * It's important to keep a reference to the function that's attached to the window.
 *
 * So, we can remove the listener when MFE unmounts.
 */
let logoutCallback: (evt: RequestTypes.UnauthorizedEventType) => void;

const addLogoutListener = (history: History) => {
  logoutCallback = once((evt: RequestTypes.UnauthorizedEventType) => {
    doLogout(history, evt, 'api-unauthorized');
  });

  // run it once as the listener unmounts, and add listener back when it mounts again
  window.addEventListener('api-unauthorized', logoutCallback, { once: true });
};

const removeLogoutListener = () => {
  window.removeEventListener('api-unauthorized', logoutCallback);
};

export const LogoutListener = {
  doLogout,
  addLogoutListener,
  removeLogoutListener
};
