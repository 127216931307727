import type { LoadOptions } from '@edapp/analytics-tracking';
import { SegmentPlugin } from '@edapp/analytics-tracking';

export const segmentOptions: Partial<LoadOptions> = {
  // Disable default destinations,
  // ensure we can use the custom segment plugin
  // which inherits the Segment instance from the shell application
  destinations: {
    all: { disabled: true }
  },
  plugins: [new SegmentPlugin()]
};
