import * as React from 'react';

import type { History } from 'history';

import { queryParamAccessor } from '@edapp/utils';

export const PLATFORM_LOCAL_STORAGE = 'ed:sc-platform';
export const PLATFORM_QUERY_PARAM = 'platform';
export type Platform = 'ios' | 'android';

export const validatePlatformQueryParam = (value: string) => {
  const validPlatforms: Platform[] = ['ios', 'android'];
  return validPlatforms.includes(value as Platform);
};

type ContextType = {
  /**
   * `platform` is a query parameter passed by SC mobile native apps to communicate their platforms
   */
  platform?: Platform;
  /**
   * Boolean that indicates whether training is currently within the bounds of SC mobile app
   */
  isSCMobile: boolean;
  /**
   * History object to be used when navigating to a page outside of training within the SC app
   */
  scHistory?: History;
  /**
   * Boolean for features to know whether they are within the MFE app
   */
  isMfe: boolean;
};

const SCPlatformContext = React.createContext<ContextType>({
  platform: undefined,
  isSCMobile: false,
  scHistory: undefined,
  isMfe: false
});

export const ScPlatformContextProvider: React.FC<React.PropsWithChildren<{ scHistory?: History; isMfe?: boolean }>> = ({
  children,
  scHistory,
  isMfe = false
}) => {
  const platform = queryParamAccessor<Platform>(
    PLATFORM_QUERY_PARAM,
    PLATFORM_LOCAL_STORAGE,
    validatePlatformQueryParam
  );
  const isSCMobile: boolean = platform === 'ios' || platform === 'android';

  return (
    <SCPlatformContext.Provider value={{ platform, isSCMobile, scHistory, isMfe }}>
      {children}
    </SCPlatformContext.Provider>
  );
};

export const useScPlatformContext = () => {
  const context = React.useContext(SCPlatformContext);

  if (!context) {
    throw new Error(
      'Usage of "useScPlatformContext" should be within the SCPlatformContext.Provider component'
    );
  }

  return context;
};
