import './types';

type Platform = 'ios' | 'android' | null;

/**
 * These are the methods injected by the mobile side to the webview.
 */
export interface SCWebview {
  /**
   * The platform of the webview.
   * `iOS` or `Android`.
   */
  platform: Platform;

  /**
   * Returns network status from mobile side
   */
  isOnline: boolean;

  /**
   * Downloads an asset from the given URL.
   * Stores the asset in the mobile side under `folderPath`.
   */
  downloadAsset: (folderPath: string, assetRemoteUrl: string) => Promise<string>;

  /**
   * Deletes an asset from the mobile side.
   */
  deleteAsset: (folderPath: string, assetRemoteUrl: string) => Promise<void>;

  /**
   * Deletes a group of assets from the mobile side.
   */
  deleteGroupOfAssets: (folderPath: string) => Promise<void>;

  /**
   * Returns a list of already downloaded assets.
   */
  getDownloadedAssets: () => Promise<{ [folderPath: string]: string[] }>;

  /**
   * Normalize path based on prefix from device.
   *
   * Usually only applicable for iOS.
   */
  normalizePath: (path: string) => string;
}

/**
 * Default implementation of the SCWebview.
 *
 * This will be the fallback in case mobile doesn't inject anything.
 * (or running in browser)
 */
class DefaultSCWebview implements SCWebview {
  platform: Platform = null;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public downloadAsset = async (groupId: string, assetRemoteUrl: string) => {
    return new Promise<string>(res => {
      setTimeout(() => {
        res(assetRemoteUrl);
      }, 1000);
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public deleteAsset = (groupId: string, assetRemoteUrl: string) => {
    return Promise.resolve();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public deleteGroupOfAssets = (groupId: string) => {
    return Promise.resolve();
  };

  public getDownloadedAssets = () => {
    return Promise.resolve({});
  };

  public get isOnline() {
    return window.navigator.onLine;
  }

  public normalizePath = (path: string) => {
    return path;
  };
}

/**
 * Creates a new instance of the SCWebview.
 *
 * If the mobile side injected the SCWebview, it will return that instance.
 * Otherwise, it will return the default implementation.
 */
export const createWebview = (): SCWebview => {
  const windowSCWebview = window.__scWebview as SCWebview | undefined;

  if (windowSCWebview) {
    return windowSCWebview;
  }

  // Fallback default implementation
  return new DefaultSCWebview();
};
