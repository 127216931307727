import * as React from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';
import type { ReactAppOrParcel } from 'single-spa-react';
import { ErrorLogger, SentryAdapter } from '@edapp/monitoring';
import { HostedWebviewUtils } from '@edapp/uxp-webview';
import { Error } from './components/Error';
import { itly } from '@edapp/analytics-tracking';
import { segmentOptions } from './constants/segment';
import { LogoutListener } from './helpers/logout-listener';
import type { History } from 'history';
import i18n from 'i18next';
import type { ThemeSetting } from './types';
import { createWebview } from '@edapp/uxp-webview';
import { initMFELocalization } from '@edapp/translations';
import { getInfraMigrationStatus } from './api/infra-migration-status/api';

type ExtraProps = {
  containerId: string;
  lang: string;
  history: History;
  theme: ThemeSetting;
};

const lifecycles = singleSpaReact<ExtraProps>({
  React,
  ReactDOMClient,
  loadRootComponent: () =>
    import(/* webpackChunkName: "app" */ './app').then(({ App }) => props => {
      const { history, theme, lang } = props as ExtraProps;
      return <App scHistory={history} theme={theme} lang={lang} />;
    }),
  domElementGetter: ({ containerId }) => document.getElementById(containerId)!,
  errorBoundary: err => {
    HostedWebviewUtils.triggerBootstrapError('error_boundary');
    ErrorLogger.captureEvent('ErrorBoundary', 'error', { err });
    return <Error />;
  }
});

const bootstrap: ReactAppOrParcel<ExtraProps>['bootstrap'] = async ({ lang, history, ...args }) => {
  ErrorLogger.init(() => new SentryAdapter({ app: 'training' }));

  try {
    await initMFELocalization(lang, ['training']);
  } catch (e) {
    HostedWebviewUtils.triggerBootstrapError('load_i18n_failed');
    throw e;
  }

  itly.load({
    validation: {
      disabled: false,
      trackInvalid: true,
      errorOnInvalid: true
    } as any,
    ...segmentOptions
  });

  const webview = createWebview();
  window.__scWebview = webview;

  // Use s12 token for Training app and parcels
  window.__disableEdAppTokens = true;

  try {
    const response = await getInfraMigrationStatus();
    window.__isMigratedToSCInfra = response.is_migrated_to_sc_infra;
  } catch (e) {
    if (e.code === 401) {
      LogoutListener.doLogout(history, null, 'get-infra-migration-status');
    } else {
      ErrorLogger.captureEvent('getInfraMigrationStatus', 'error', { e });
    }
    throw e;
  }

  await lifecycles.bootstrap({ lang, history, ...args });
};

const mount: ReactAppOrParcel<ExtraProps>['mount'] = async ({ history, lang, ...args }) => {
  LogoutListener.addLogoutListener(history);
  i18n.changeLanguage(lang);
  await lifecycles.mount({ history, lang, ...args });
};

const unmount: ReactAppOrParcel<ExtraProps>['mount'] = async (...args) => {
  await lifecycles.unmount(...args);
  LogoutListener.removeLogoutListener();
};

const metadata = {
  sentryDSN: 'https://35ca0e45344f5dabc46b031ac201098c@o176876.ingest.sentry.io/4506613467840512'
};

export { bootstrap, mount, unmount, metadata };
