import { Box, SomethingWentWrongSvg } from '@edapp/ed-components';
import { Text, Link } from '@edapp/sc-web-ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SCThemeContextProvider } from '@edapp/themes';

const SC_CONTACT_US = 'https://safetyculture.com/contact-us';

export const Error: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SCThemeContextProvider initialThemeSetting="light">
      <Box
        flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        gap={3}
        py={6}
        px={2}
        style={{ height: '100%' }}
        bgColor="lightBlue"
      >
        <SomethingWentWrongSvg />
        <Box flex flexDirection="column" alignItems="center" gap={1}>
          <Text variant="bodyMediumStrong">
            {t('messages.something-went-wrong', { ns: 'training' })}
          </Text>
          <Text variant="bodyMedium">
            {t('messages.something-went-wrong-description', { ns: 'training' })}
          </Text>
        </Box>
        <Link type="button" href={SC_CONTACT_US} variation="tertiary">
          {t('messages.contact-support', { ns: 'training' })}
        </Link>
      </Box>
    </SCThemeContextProvider>
  );
};
